// heavily copied from Chakra-UI docs site at
// https://github.com/chakra-ui/chakra-ui-docs/blob/main/src/components/algolia-search.tsx
import React from 'react';
import Helmet from 'react-helmet';
import algoliasearch from 'algoliasearch/lite';
import { Highlight, Hits, InstantSearch, PoweredBy, useSearchBox, Snippet } from 'react-instantsearch-hooks-web';

import {
  FaSearch
} from "react-icons/fa"
import {
  Box,
  IconButton,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import Link from "../Link"

const rawClient = algoliasearch("5U8Z520ZJH", "d5eb4c0b0b48dbaa08a1bf1bf461becb");
// proxy raw client to suppress search on page load.  Might as well require 3 or more chars.
// https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/
const searchClient = {
  ...rawClient,
  search(requests) {
    if (requests.every(({ params }) => !(params.query && params.query.length > 2))) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
    return rawClient.search(requests);
  },
};
const searchIndices = [
  {
    name: `netlify_ee54b7f2-2c3e-4aa5-8941-67a9e4bae2ed_main_all`,
    title: `Pages`,
  },
];


function Hit({ hit }) {
  return (
    <Box 
      as="article" 
      textStyle="plainLinks"
      borderWidth='1px' 
      borderRadius='lg'
      borderStyle="solid"
      borderColor="brand.green"
      overflow="hidden"
      my="1rem"
    >
      <Link href={hit.url}>
        <Image src={hit.image} />
        <Box p={5}>
        <Heading fontSize="2xl" pb={2}>
            <Highlight attribute="title" hit={hit} />
        </Heading>
        <Text>
          <Snippet attribute="content" hit={hit} />
        </Text>
        </Box>
      </Link>
    </Box>
  );
}

const SearchButton = ({ onClick }) => (
  <IconButton
    aria-label="Open Search"
    fontSize="xl"
    variant="ghost"
    icon={<FaSearch />}
    onClick={onClick}
  /> 
);

// Easiest way to hide clear/search icons was to setup my own box
function CustomSearchBox(props) {
  const { refine, isSearchStalled } = useSearchBox();
  return (
  <InputGroup {...props}>
    <InputLeftElement><FaSearch /></InputLeftElement>
    <Input onInput={(e)=>{refine(e.target.value)}}/>
    <InputRightElement>
      {isSearchStalled && <Spinner color='brand.green'/>}
    </InputRightElement>
  </InputGroup>);
}

function Search() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [initialQuery, setInitialQuery] = React.useState(null)

  return (
    <>
      <Helmet>
        <link
          rel='preconnect'
          href='https://5U8Z520ZJH-dsn.algolia.net'
          crossOrigin='true'
        />
      </Helmet>
      <SearchButton onClick={onOpen}/>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody textStyle="search" mt="0.6rem">
              <InstantSearch 
                searchClient={searchClient} 
                indexName={searchIndices[0].name}
              >
                <CustomSearchBox />
                <Hits hitComponent={Hit} />
              </InstantSearch>
            </ModalBody>
            <ModalFooter>
              <Box h="1rem" w="40%">
                <PoweredBy/>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default Search
